import { errorMsg } from '../constants/messages';

const planSponsorListFormData = [
    {
        title: 'Plan Sponsor Details',
        name: 'id',
        label: 'Plan Sponsor Company Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Sponsor Company Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyName',
        label: 'Plan Sponsor Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Sponsor Company Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'sicCode',
        label: 'SIC Code',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'SIC Code' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyWebsite',
        label: 'Website',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Website' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyAddress',
        label: 'Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Address' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'plan_end',
        label: 'Created By',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Created By' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'createdAt',
        label: 'Created On',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Created On' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'plan_end',
        label: 'Updated By',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Updated By' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'updatedAt',
        label: 'Updated On',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Updated On' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { planSponsorListFormData };
