/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Table, Checkbox, Form } from 'antd';

import { extractEntityNames } from '../../utils/functions';
import { entityArray } from '../../constants/AppConstant';

interface DataItem {
    key: string;
    name: string;
    all?: any;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    delete?: boolean;
}

const UserRolePermission = (props: any) => {
    const { formTemplate, index, form } = props;
    const [data, setData] = useState<DataItem[]>([]);
    const tableData = props.form.getFieldValue(formTemplate.name) || [];

    const columns = [
        {
            title: 'Module Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'All',
            dataIndex: 'all',
            key: 'all',
            render: (_: any, record: DataItem) => (
                <Checkbox
                    checked={record.all}
                    onChange={(e) => handleCheckboxChange(record, 'all', e.target.checked)}
                />
            ),
        },
        {
            title: 'Create',
            dataIndex: 'create',
            key: 'create',
            render: (_: any, record: DataItem) => (
                <Checkbox
                    checked={record.create}
                    onChange={(e) => handleCheckboxChange(record, 'create', e.target.checked)}
                />
            ),
        },
        {
            title: 'Read',
            dataIndex: 'read',
            key: 'read',
            render: (_: any, record: DataItem) => (
                <Checkbox
                    checked={record.read}
                    onChange={(e) => handleCheckboxChange(record, 'read', e.target.checked)}
                />
            ),
        },
        {
            title: 'Update',
            dataIndex: 'update',
            key: 'update',
            render: (_: any, record: DataItem) => (
                <Checkbox
                    checked={record.update}
                    onChange={(e) => handleCheckboxChange(record, 'update', e.target.checked)}
                />
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (_: any, record: DataItem) => (
                <Checkbox
                    checked={record.delete}
                    onChange={(e) => handleCheckboxChange(record, 'delete', e.target.checked)}
                />
            ),
        },
    ];

    const handleCheckboxChange = (record: DataItem, type: keyof DataItem, checked: boolean) => {
        const newData = data.map((item: DataItem) => {
            if (item.key === record.key) {
                if (type === 'all') {
                    return {
                        ...item,
                        all: checked,
                        create: checked,
                        read: checked,
                        update: checked,
                        delete: checked,
                    };
                } else {
                    const updatedItem = {
                        ...item,
                        [type]: checked,
                    };
                    const allChecked =
                        updatedItem.create && updatedItem.read && updatedItem.update && updatedItem.delete;
                    return {
                        ...updatedItem,
                        all: allChecked,
                    };
                }
            }
            return item;
        });
        setData(newData);
    };

    const createTableData = () => {
        const actionsMap = tableData.reduce((acc: any, item: any) => {
            acc[item.subject] = item.actions;
            return acc;
        }, {});

        return extractEntityNames(entityArray).map((moduleName: string, index: number) => {
            const actions = actionsMap[moduleName] || [];
            const newData = {
                key: String(index),
                name: moduleName,
                create: false,
                read: false,
                update: false,
                delete: false,
                ...actions.reduce((acc: any, action: any) => {
                    acc[action] = true;
                    return acc;
                }, {}),
            };
            return newData;
        });
    };

    useEffect(() => {
        setData(createTableData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        form.setFieldsValue({ 'permissions': data });
    }, [data, form]);

    return (
        <Form.Item
            key={index}
            name={formTemplate.name}
            colon={false}
            rules={[
                {
                    required: formTemplate.required,
                    message: formTemplate.errorMsg,
                },
            ]}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    current: 1,
                    pageSize: 20,
                }}
            />
        </Form.Item>
    );
};

export default UserRolePermission;
