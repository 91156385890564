/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Select } from 'antd';
import { PlusSquareOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';

import callApi from '../../Api/config';
import { APPLICATION_AUTH_USER, USERS } from '../../constants/EntityName';
import { toastError } from '../../service/Toast';
import './style.scss';

interface SearchPlanSponsorModalProps {
    isVisible: boolean;
    onClose: () => void;
    applicationId?: string;
    refreshData?: () => void;
    planSponsorAuthName: any;
}

const SearchPlanSponsorModal: React.FC<SearchPlanSponsorModalProps> = ({
    isVisible,
    onClose,
    applicationId,
    refreshData,
    planSponsorAuthName,
}) => {
    const [userNameData, setUserNameData] = useState<any>();
    const [selectedUserId, setSelectedUserId] = useState<string>();

    const onCreateUser = () => {
        window.location.href = '/create-user';
    };

    useEffect(() => {
        const fetchApplicationData = async () => {
            try {
                const response = await callApi(USERS, 'GET');
                if (response?.status) {
                    setUserNameData(response?.data?.data?.results);
                } else {
                    console.error('Failed to fetch application data');
                }
            } catch (error) {
                toastError('Error fetching application data');
            }
        };

        fetchApplicationData();
    }, []);

    const onAddUser = async () => {
        const payload = {
            'userId': selectedUserId,
            'applicationId': applicationId,
        };
        try {
            const response = await callApi(APPLICATION_AUTH_USER, 'POST', payload);
            if (response?.status) {
                onClose();
                refreshData && refreshData();
            }
        } catch (error) {
            console.error('Error occured', error);
        }
    };

    const handleClose = () => {
        setSelectedUserId(undefined);
        onClose();
    };

    return (
        <Modal visible={isVisible} onCancel={handleClose} footer={null} width={400} className='plan-sponsor-user-modal'>
            <div className='modal-icon'>
                <UserOutlined />
            </div>
            <h2>
                Select or search for plan <br />
                sponsor users
            </h2>
            <p>Quickly find and manage plan sponsor users.</p>
            <Select
                showSearch
                placeholder='Select a person'
                optionFilterProp='label'
                value={selectedUserId}
                onSelect={(value) => setSelectedUserId(value)}
                options={userNameData?.map((item: any) => ({
                    label: item.fullName,
                    value: item.id,
                    disabled: planSponsorAuthName?.includes(item.fullName),
                }))}
            />
            <div className='modal-actions'>
                <Button icon={<UserAddOutlined />} onClick={onCreateUser} className='create-btn'>
                    Create user
                </Button>
                <Button type='primary' icon={<PlusSquareOutlined />} onClick={onAddUser} className='add-user-btn'>
                    Add user
                </Button>
            </div>
        </Modal>
    );
};

export default SearchPlanSponsorModal;
