/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import { toastError, toastSuccess } from '../../service/Toast';
import callApi from '../../Api/config';
import { RESET_FORGOT_PASSWORD } from '../../constants/EntityName';
import { REGEX_PASSWORD, REGEX_PASSWORD_LENGTH_LIMIT } from '../../utils/Regexp/Regex';

import './style.scss';

const ResetForgotPassword: React.FC = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordError, setPasswordError] = useState<boolean>(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get('token');

    useEffect(() => {
        if (!token) {
            toastError('Invalid or missing token.');
        }
    }, [token]);

    const handlePasswordReset = async (e: any) => {
        e.preventDefault();

        const payload = {
            password: newPassword,
        };

        if (!newPassword || !confirmPassword) {
            toastError('Please enter your new password.');
        }
        if (newPassword !== confirmPassword) {
            toastError('Passwords do not match.');
        }
        if (!REGEX_PASSWORD_LENGTH_LIMIT.test(newPassword) || !REGEX_PASSWORD_LENGTH_LIMIT.test(confirmPassword)) {
            toastError('Password or Confirm Password does not meet the required criteria.');
            return;
        }
        try {
            const res = await callApi(RESET_FORGOT_PASSWORD, 'PATCH', payload, { token });
            if (res?.data?.statusCode === 200) {
                toastSuccess('Password changed successfully.');
                setNewPassword('');
                setConfirmPassword('');
                setPasswordError(false);
            } else {
                toastError(res?.data?.message);
            }
        } catch (error) {
            toastError('An error occurred while resetting your password.');
        }
    };

    return (
        <div className='updatePasswordContainer'>
            <Form name='basic' initialValues={{ remember: true }} autoComplete='off' layout='vertical'>
                <h1>Reset Password</h1>
                <Form.Item
                    label='Enter New Password'
                    rules={[
                        { required: true, message: 'Please enter your new password!' },
                        { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                    ]}>
                    <Input.Password
                        placeholder='Enter new password'
                        onChange={(e: any) => setNewPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label='Re-enter New Password'
                    rules={[
                        { required: true, message: 'Please re-enter your new password!' },
                        { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                    ]}>
                    <Input.Password
                        placeholder='Re-Enter new password'
                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                    />
                </Form.Item>

                <p
                    style={{
                        color: '#ff4d4f',
                        visibility: passwordError ? 'visible' : 'hidden',
                    }}>
                    Please check both passwords.
                </p>

                <Form.Item>
                    <Button type='primary' htmlType='submit' onClick={handlePasswordReset}>
                        Reset Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ResetForgotPassword;
