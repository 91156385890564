/* eslint-disable no-console */
import { Form, Input, Select, Button, AutoComplete } from 'antd';
import { useHistory } from 'react-router-dom';

import { useEffect, useState } from 'react';

import DefaultProfileHeader from '../Entity/AbstractLanding/DefaultProfileHeader';
import DefaultTableHeader from '../Entity/AbstractLanding/DefaultTableHeader';
import FooterComponent from '../../components/FooterComponent';
import callApi from '../../Api/config';
import { USER_TITLES, USERS } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';
import UsaFlag from '../../assets/png/Flag_usa.png';

import './style.scss';

const { Option } = Select;

const CreateUser = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [titleOptionData, setTitleOptionData] = useState<string[]>();

    const onFinish = async (value: any) => {
        const payload = { ...value, phoneNumber: value.phoneNumber.replace(/-/g, '') }; // remove dashes before sending

        try {
            const response = await callApi(USERS, 'POST', payload);
            console.log(response, 'response');

            if (response?.status) {
                toastSuccess('User Created successfully!');
                history.push('/user');
            } else {
                toastError(response?.data?.error);
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 6) {
            input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
        } else if (input.length > 3) {
            input = `${input.slice(0, 3)}-${input.slice(3, 6)}`;
        }
        setFormattedPhoneNumber(input);
        form.setFieldsValue({ phoneNumber: input });
    };

    const fetchUserTitles = async () => {
        try {
            const response = await callApi(USER_TITLES, 'GET');

            if (response?.status) {
                setTitleOptionData(response?.data?.data);
            } else {
                toastError('Error fetching User titles');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchUserTitles();
    }, []);

    const handleCancel = () => {
        history.push('/user');
    };

    return (
        <div className='create-user-container'>
            <DefaultProfileHeader />
            <div style={{ paddingLeft: '15px' }}>
                <DefaultTableHeader
                    headerName={'User Management'}
                    setSearchQuery={() => 'test'}
                    setPhoneSearchQuery={() => 'test'}
                    setCitySearchQuery={() => 'test'}
                    setStateSearchQuery={() => 'test'}
                    disableSearch={true}
                    disableAdd={true}
                    isShowUploadButton={false}
                    isCreateMode={true}
                />
            </div>
            <div className='user-create-form-container'>
                <Form form={form} layout='vertical' className='user-create-form' onFinish={onFinish}>
                    <div className='form-row'>
                        <Form.Item
                            name='fullName'
                            label='Full Name'
                            rules={[
                                { required: true, message: 'Full Name is required' },
                                {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: 'Full Name can only contain letters and spaces',
                                },
                                { max: 100, message: 'Full Name cannot exceed 100 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[
                                { required: true, message: 'Email is required' },
                                { type: 'email', message: 'Please enter a valid email' },
                                { max: 254, message: 'Email cannot exceed 254 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='phoneNumber'
                            label='Phone Number'
                            rules={[
                                { required: true, message: 'Phone Number is required' },
                                {
                                    pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                                    message: 'Please enter a valid phone number',
                                },
                            ]}>
                            <div className='us-flag-input'>
                                <div className='us-flag'>
                                    <img src={UsaFlag} alt='USA flag' width={34} height={18} />
                                    <span style={{ paddingLeft: '5px' }}>+1</span>
                                </div>
                                <Input value={formattedPhoneNumber} onChange={handlePhoneNumberChange} maxLength={12} />
                            </div>
                        </Form.Item>
                    </div>
                    <div className='form-row'>
                        <Form.Item
                            name='title'
                            label='Title'
                            rules={[{ required: true, message: 'Title is required' }]}>
                            <AutoComplete
                                options={titleOptionData?.map((title) => ({ value: title }))}
                                filterOption={(inputValue, option) =>
                                    option ? option.value.toLowerCase().includes(inputValue.toLowerCase()) : false
                                }>
                                <Input />
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item
                            name='roleName'
                            label='Role'
                            rules={[{ required: true, message: 'Role is required' }]}>
                            <Select>
                                <Option value='Plan Sponsor'>Plan Sponsor</Option>
                                <Option value='Advisor'>Advisor</Option>
                                <Option value='Agent'>Agent</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ visibility: 'hidden' }}
                            name='roleName'
                            label='Role'
                            rules={[{ required: true, message: 'Role is required' }]}>
                            <Select>
                                <Option value='Plan Sponsor'>Plan Sponsor</Option>
                                <Option value='Advisor'>Advisor</Option>
                                <Option value='Agent'>Agent</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='form-actions'>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type='primary' htmlType='submit'>
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
            <FooterComponent />
        </div>
    );
};

export default CreateUser;
