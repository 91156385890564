import React, { useState } from 'react';
import { Dropdown, Button, Checkbox } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import FilterIcon from '../../assets/svg/filterIcon.svg';
import './styles.scss';

const StatusDropdown = ({ filterOption, filterKey }: { filterOption: string[]; filterKey: string }) => {
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const handleChange = (checkedValues: any) => {
        setSelectedStatuses(checkedValues);
    };

    const handleReset = () => {
        setSelectedStatuses([]);

        const params = new URLSearchParams(location.search);
        params.delete(filterKey);
        history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });

        setDropdownVisible(false);
    };

    const handleOk = () => {
        const params = new URLSearchParams(location.search);

        params.delete(filterKey);

        selectedStatuses.forEach((status) => {
            params.append(filterKey, status);
        });

        history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });

        setDropdownVisible(false);
    };

    const menu = (
        <div className='status-dropdown-menu absolute-dropdown'>
            <Checkbox.Group
                options={filterOption}
                value={selectedStatuses}
                onChange={handleChange}
                className='status-checkbox-group'
            />
            <div className='dropdown-footer'>
                <Button onClick={handleReset} className='reset-button'>
                    Reset
                </Button>
                <Button type='primary' onClick={handleOk}>
                    OK
                </Button>
            </div>
        </div>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            placement='bottomLeft'
            visible={dropdownVisible}
            onVisibleChange={(visible) => setDropdownVisible(visible)}>
            <img style={{ cursor: 'pointer' }} src={FilterIcon} alt='Filter Icon' height={11} width={10} />
        </Dropdown>
    );
};

export default StatusDropdown;
