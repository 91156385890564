/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker, Table } from 'antd';
import moment from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DownOutlined, EditFilled, RightCircleOutlined } from '@ant-design/icons';

import { InputInterface, OnAddArguments } from '../../../utils/forms/FormInterface';
import useEffectExcludingMount from '../../../hooks/useEffectExcludingMount';
import allowTableShort from '../../../utils/TableShort';
import DefaultTableHeader from './DefaultTableHeader';
import callApi from '../../../Api/config';
import Dropdown from '../../../components/Dropdown';

import CheckIcon from '../../../assets/svg/checkIcon.svg';
import DraftIcon from '../../../assets/svg/draftIcon.svg';
import DeniedIcon from '../../../assets/svg/deniedIcon.svg';
import ShareIcon from '../../../assets/svg/shareIcon.svg';
import WaitIcon from '../../../assets/svg/waiting.svg';

import '../entity.scss';
import { AppRoutes } from '../../../constants/AppConstant';
import { APPLICATION, PLAN_SPONSOR, USERS } from '../../../constants/EntityName';
import ResetPasswordModal from '../../../components/ResetPasswordModal';
import DefaultProfileHeader from './DefaultProfileHeader';
import AssignAgentIcon from '../../../assets/svg/assign_agent.svg';
import StatusDropdown from '../../../components/StatusDropdown';

type FilterList = any;

interface AbstractLandingProps {
    headerName: string;
    entityName: string;
    tableHeaders: Array<InputInterface>;
    refreshData?: boolean;
    onAdd?: (argument1?: OnAddArguments) => void;
    onSlotClick?: any;
    addName?: string;
    entityId?: string;
    code?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    filtersList?: FilterList[];
    setFiltersList?: React.Dispatch<React.SetStateAction<FilterList[]>>;
    handleEdit?: (userData: any) => void;
    orderStatusQueryParams?: any;
    handleModal?: () => void;
    onSelect?: (arg: any) => any;
    paymentToggleStatus?: (status: any) => any | undefined;
    serviceToggleStatus?: (status: any) => any | undefined;
    recordCount?: (count: any) => any | undefined;
    isCreateMode?: boolean;
}

let previousPaginationCount = 0;

const AbstractLanding = (props: AbstractLandingProps) => {
    const {
        entityName,
        onSlotClick,
        disableSearch,
        filtersList,
        paymentToggleStatus,
        serviceToggleStatus,
        recordCount,
        isCreateMode,
    } = props;
    const [apiListingData, setApiListingData] = useState<any>([] as any);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [totalCount, setTotalCount] = useState<number>();
    const [searchQuery, setSearchQuery] = useState<string>();
    const [searchPhoneQuery, setPhoneSearchQuery] = useState<string>();
    const [searchCityQuery, setCitySearchQuery] = useState<string>();
    const [searchStateQuery, setStateSearchQuery] = useState<string>();
    const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);
    const [userId, setUserId] = useState('');
    const [isDropdownVisible, setDropdownVisible] = useState<any>({});
    const [isApplicationDropdownVisible, setApplicationDropdownVisible] = useState<boolean>(false);
    const location = useLocation();

    let filterKey = '';
    if (entityName === APPLICATION) {
        filterKey = 'adminStatus';
    }
    if (entityName === USERS) {
        filterKey = 'userRole';
    }
    const toggleDropdown = (value: string) => {
        setDropdownVisible({ ...isDropdownVisible, [value]: value });
    };

    const [selectedFilter, setSelectedFilter] = useState<Array<{ key: string; value: CheckboxValueType[] }>>([]);

    const PlanSponsorOptions = useMemo(
        () => apiListingData.filter((item: any) => item.role !== 'Plan Sponsor').map((item: any) => item.full_name),
        [apiListingData],
    );

    const AdvisorOptions = useMemo(
        () =>
            apiListingData
                .filter((item: any) => item.role !== 'Advisor' || item.role !== 'Plan Sponsor')
                .map((item: any) => item.full_name),
        [apiListingData],
    );

    const AgentOptions = useMemo(
        () =>
            apiListingData
                .filter((item: any) => item.role !== 'Plan Sponsor' || item.role !== 'Agent')
                .map((item: any) => item.full_name),
        [apiListingData],
    );

    const getOptions = (roleName: string) => {
        switch (roleName) {
            case 'Agent':
                return ['Select an option', 'None', ...PlanSponsorOptions];
            case 'Advisor':
                return ['Select an option', 'None', ...AdvisorOptions];
            case 'Plan Sponsor':
                return ['Select an option', 'None', ...AgentOptions];
            default:
                return ['None'];
        }
    };

    const dropDownOnChange = (e: any, recordId: string) => {
        const value = e.target.value;
        if (value === 'None') {
            setDropdownVisible((prevState: any) => {
                const newState = { ...prevState };
                delete newState[recordId];
                return newState;
            });
        }
        props.onSelect && props.onSelect(e);
    };

    const handleEditClick = (id: any) => {
        setUserId(id);
        setOpenUpdatePasswordModal(true);
    };

    const onCancelClose = () => {
        setOpenUpdatePasswordModal(false);
    };

    const getData = async (paginationEvent = pagination) => {
        const queryParams: any = {};
        let res: any;

        const urlParams = new URLSearchParams(window.location.search);

        const filterParams = urlParams.getAll(filterKey);
        if (filterParams.length > 0) {
            queryParams[filterKey] = filterParams;
        }

        if (props.entityId && props.code) {
            queryParams[props.code] = props.entityId;
        }

        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }

        if (searchQuery) {
            if (entityName === AppRoutes.OrderList) {
                queryParams.orderId = searchQuery;
            } else {
                queryParams.search = searchQuery;
            }
        }

        const queryString = Object.keys(queryParams).reduce((acc, key) => {
            if (Array.isArray(queryParams[key])) {
                queryParams[key].forEach((value: string) => {
                    acc.append(key, value);
                });
            } else {
                acc.append(key, queryParams[key]);
            }
            return acc;
        }, new URLSearchParams());

        setLoading(true);

        const apiUrl = `${props.entityName}?${queryString.toString()}`;

        try {
            res = await callApi(apiUrl, 'GET');
            if (res?.status && res?.data?.data) {
                const apiData = res?.data?.data;

                const totalCount = apiData?.total;

                // const totalCount = apiData?.results?.length >= 10 ? (+apiData.page + 1) * 10 : +apiData.page * 10;

                setPaginationCount(totalCount);
                recordCount && recordCount(res?.data?.data?.totalCount);

                if (entityName === USERS) {
                    res.data.data.results = res?.data?.data?.results.map((i: any) => ({
                        ...i,
                        roleName: i?.role,
                        planSponsorCompany: (
                            <span>
                                {' '}
                                {i?.applications
                                    ?.map((item: any) => item.companyName)
                                    .filter(Boolean)
                                    .join(', ') || '-'}
                            </span>
                        ),
                        email: <span style={{ color: '#13A3E0' }}>{i?.email}</span>,
                    }));
                }

                if (entityName === PLAN_SPONSOR) {
                    res.data.data.results = res?.data?.data?.results.map((item: any) => {
                        const createdAt = new Date(item.createdAt);
                        const updatedAt = new Date(item.updatedAt);

                        const formattedCreatedAt = `${createdAt.getMonth().toString().padStart(2, '0')}/${(createdAt.getDate() + 1).toString().padStart(2, '0')}/${createdAt.getFullYear()}`;
                        const formattedUpdatedAt = `${updatedAt.getMonth().toString().padStart(2, '0')}/${(updatedAt.getDate() + 1).toString().padStart(2, '0')}/${updatedAt.getFullYear()}`;

                        return {
                            ...item,
                            createdAt: item?.createdAt ? formattedCreatedAt : '-',
                            updatedAt: item?.updatedAt ? formattedUpdatedAt : '-',
                            createdBy: item?.createdBy?.fullName,
                            updatedBy: item?.updatedBy?.fullName,
                            website: (
                                <a
                                    style={{ textDecoration: 'underline', color: '#000' }}
                                    href={item?.website?.startsWith('http') ? item.website : `https://${item.website}`}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    {item?.website}
                                </a>
                            ),
                        };
                    });
                }

                if (entityName === APPLICATION) {
                    res.data.data.results = res?.data?.data?.results.map((i: any) => ({
                        ...i,
                        companyName: i?.companyName,
                        applicantName: i?.createdBy?.fullName,
                        authorizedSignature: i?.authorizedUsers[0]?.authorizedUserId?.fullName,
                        submittedDate: i.submittedDate && moment(i.submittedDate).format('MM/DD/YYYY'),
                        adminStatus:
                            i.adminStatus === 'DRAFT' ? (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: 'rgba(255, 214, 0, 0.44)',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '28px',
                                        color: '#000',
                                        padding: '3px',
                                        width: 'fit-content',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    }}
                                    onClick={() => setApplicationDropdownVisible(true)}>
                                    <img src={DraftIcon} alt='Draft Icon' width={16} height={16} />
                                    <p
                                        style={{
                                            marginBottom: '0',
                                            marginLeft: '10px',
                                            marginTop: '3px',
                                        }}>
                                        Draft
                                    </p>
                                </div>
                            ) : i.adminStatus === 'Agreement Shared' ? (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#D0FFEC',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '28px',
                                        color: '#009257',
                                        padding: '3px',
                                        width: 'fit-content',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    }}
                                    onClick={() => setApplicationDropdownVisible(true)}>
                                    <img src={ShareIcon} alt='Share Icon' width={16} height={16} />
                                    <p style={{ marginBottom: '0', marginLeft: '10px', marginTop: '3px' }}>
                                        Agreement Shared
                                    </p>
                                </div>
                            ) : i.adminStatus === 'Waiting for OFAC' ? (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: 'rgba(255, 214, 0, 0.44)',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        borderRadius: '28px',
                                        color: '#000',
                                        alignItems: 'center',
                                        padding: '3px',
                                        width: 'fit-content',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    }}
                                    onClick={() => setApplicationDropdownVisible(true)}>
                                    <img src={WaitIcon} alt='Wait Icon' width={16} height={16} />
                                    <p style={{ marginBottom: '0', marginLeft: '10px', marginTop: '3px' }}>
                                        Waiting for OFAC
                                    </p>
                                </div>
                            ) : i.adminStatus === 'OFAC completed' ? (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#D0FFEC',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        borderRadius: '28px',
                                        color: '#009257',
                                        alignItems: 'center',
                                        padding: '3px',
                                        width: 'fit-content',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    }}
                                    onClick={() => setApplicationDropdownVisible(true)}>
                                    OFAC completed
                                </div>
                            ) : i.adminStatus === 'Invoice shared' ? (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#D0FFEC',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        borderRadius: '28px',
                                        color: '#009257',
                                        alignItems: 'center',
                                        padding: '3px',
                                        width: 'fit-content',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    }}
                                    onClick={() => setApplicationDropdownVisible(true)}>
                                    <img src={ShareIcon} alt='Share Icon' width={16} height={16} />
                                    <p style={{ marginBottom: '0', marginLeft: '10px', marginTop: '3px' }}>
                                        Invoice shared
                                    </p>
                                </div>
                            ) : i.adminStatus === 'Application approved' ? (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#D0FFEC',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        borderRadius: '28px',
                                        color: '#009257',
                                        alignItems: 'center',
                                        padding: '3px',
                                        width: 'fit-content',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    }}
                                    onClick={() => setApplicationDropdownVisible(true)}>
                                    <img src={CheckIcon} alt='Check Icon' width={16} height={16} />
                                    <p style={{ marginBottom: '0', marginLeft: '10px', marginTop: '3px' }}>
                                        Application approved
                                    </p>
                                </div>
                            ) : (
                                i.adminStatus === 'Application denied' && (
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: '#FFBCBC',
                                            display: 'inline-flex',
                                            justifyContent: 'center',
                                            borderRadius: '28px',
                                            color: '#A90808',
                                            alignItems: 'center',
                                            padding: '3px',
                                            width: 'fit-content',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                        }}
                                        onClick={() => setApplicationDropdownVisible(true)}>
                                        <img src={DeniedIcon} alt='Denied Icon' width={16} height={16} />
                                        <p style={{ marginBottom: '0', marginLeft: '10px', marginTop: '3px' }}>
                                            Application denied
                                        </p>
                                    </div>
                                )
                            ),
                    }));
                }

                setApiListingData(res.data.data.results);
                setTotalCount(totalCount);
            }
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const onPageChange = async (event: any) => {
        setPagination(event);
        getData(event);
    };

    const onSummitFilter = (filterName: string, list: CheckboxValueType[]) => {
        const newFilter = selectedFilter.filter((value) => value.key !== filterName);

        if (list.length) {
            newFilter.push({
                key: filterName,
                value: list,
            });
        }
        setSelectedFilter(newFilter);
    };

    useEffectExcludingMount(() => {
        if (searchQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchPhoneQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchCityQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchStateQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
    }, [searchQuery, searchPhoneQuery, searchCityQuery, searchStateQuery]);

    useEffectExcludingMount(() => {
        getData();
    }, [props.refreshData, location.search]);

    useEffect(() => {
        getData({ ...pagination, current: 1 });
    }, [selectedFilter, entityName]);

    useEffect(() => {
        if (props.orderStatusQueryParams) {
            onSummitFilter('orderStatus', [props.orderStatusQueryParams]);
        }
    }, [props.orderStatusQueryParams]);

    const onAddClick = (record: any) => {
        if (props.onAdd) {
            props.onAdd(record);
        }
    };

    const getFilter = () => (
        <div className='flex'>
            {filtersList?.map((filter) => {
                switch (filter.filterType) {
                    case 'DatePicker':
                        return (
                            <DatePicker
                                key={filter.filterKey}
                                allowClear
                                suffixIcon={<DownOutlined />}
                                className='date-filter'
                                format='YYYY-MM-DD'
                                name={filter.filterName}
                                placeholder={filter.placeholder}
                                onChange={(_, dateString) => onSummitFilter(filter.key, [dateString])}
                            />
                        );
                    default:
                        return (
                            <Dropdown
                                checkBoxOptions={filter.options}
                                onSubmitFilter={onSummitFilter}
                                filterName={filter.filterName}
                                filterKey={filter.filterKey}
                                key={filter.filterKey}
                            />
                        );
                }
            })}
        </div>
    );

    const columns: any = props.tableHeaders.map((tableMeta: any) => ({
        key: tableMeta.tableKey,
        title: (
            <span style={{ display: 'flex' }}>
                {tableMeta.tableLabel}
                <div style={{ marginLeft: '10px' }}>
                    {tableMeta.fiterIcon && (
                        <StatusDropdown filterOption={tableMeta.filterOptions} filterKey={tableMeta.filterKey} />
                    )}{' '}
                </div>
            </span>
        ),
        dataIndex: tableMeta.tableKey,
        colSpan: tableMeta.type === 'image' ? 0 : tableMeta.type === 'combinedColumn' ? 2 : null,
        onCell: (record: any) => ({
            style: {
                cursor:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'pointer'
                        : 'default',
                textDecoration:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'underline'
                        : '',
            },
            onClick: () => {
                if (tableMeta.allowAddRecordClick) {
                    // This code may be used in future
                    // if (routesEntityArray.includes(entityName)) {
                    //     history.push(`${entityName}/${record?.id}`);
                    // } else {
                    onAddClick(record);
                    // }
                }

                // if (tableMeta.allowAddRedirectOnClicks) {
                //     history.push(`${ORDER_LIST_ENTITY}/${record.order.id}`);
                // }

                if (tableMeta.allowSlotRecordClick) {
                    const tableMetaUrl = tableMeta.url;

                    if (tableMetaUrl) {
                        history.push(`${entityName}/${record?.id}/${tableMetaUrl}`);
                    } else {
                        onSlotClick?.(tableMeta.tableKey, { id: record.id, name: record.name });
                    }
                }
            },
        }),

        sorter: allowTableShort(tableMeta.enableSort, tableMeta.tableKey),

        render: (value: any, record: any) => {
            if (tableMeta.columnType === 'userName') {
                return `${record.firstName} ${record.lastName}`;
            }

            if (tableMeta.columnType === 'orderIdHash') {
                return `${record.order.orderIdHash}`;
            }
            if (tableMeta.columnType === 'orderId') {
                return `${record.order.id}`;
            }

            if (tableMeta.columnType === 'countriesName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.countries?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'displayName') {
                // when Items are deleted then the corresponding inventories are not deleted. In that case the value will be undefined and hence the optional chaining
                return value?.name || '--';
            }

            if (tableMeta.columnType === 'servicesObjectArray') {
                return value
                    ?.map(
                        (services: { level: string; serviceDetail: string; serviceDetailLevel: string }) =>
                            `${services?.level} - ${services?.serviceDetail} - ${services?.serviceDetailLevel}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'facebookLink') {
                return `${record.socialMediaLinks.facebook} `;
            }
            if (tableMeta.columnType === 'twitterLink') {
                return `${record.socialMediaLinks.twitter}`;
            }

            if (tableMeta.columnType === 'contactNumbersMultiple') {
                return `${record.contactNumbers.join(' | ')}`;
            }

            if (tableMeta.columnType === 'documentsDisplayNameObject') {
                return (
                    record?.documents
                        ?.map((documentDisplayNameObject: { name: string }) => documentDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'featureDisplayNameObject') {
                return (
                    record?.features
                        ?.map(
                            (featuresDisplayNameObject: { description: string }) =>
                                featuresDisplayNameObject?.description,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceAttributesDisplayNameObject') {
                return (
                    record?.serviceAttributes
                        ?.map(
                            (serviceAttributeDisplayNameObject: { name: string }) =>
                                serviceAttributeDisplayNameObject?.name,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'plansDisplayNameObject') {
                return (
                    record?.plans
                        ?.map((planDisplayNameObject: { title: string }) => planDisplayNameObject?.title)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'parametersDisplayNameObject') {
                return (
                    record?.parameters
                        ?.map((parametersDisplayNameObject: { name: string }) => parametersDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'categoryName') {
                return `${record?.category?.title || '--'}`;
            }

            if (tableMeta.columnType === 'titleName') {
                return `${record?.make?.title || '--'}`;
            }

            if (tableMeta.columnType === 'FuelTypeName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.fuelType?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'discountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.discountType || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'referralDiscountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.couponTarget || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'commissionType') {
                // In that case the value will be undefined and hence the optional chaining
                const commissionTypeValue = record?.commissionType || '--';
                return commissionTypeValue.charAt(0).toUpperCase() + commissionTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'displayNameTitle') {
                return `${record?.title?.heading || '--'}`;
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'displayNameObjectArray') {
                return value
                    ?.map(
                        (displayNameObject: { name: string; mobile: string }) =>
                            `${displayNameObject?.name} - ${displayNameObject?.mobile}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'password') {
                return value?.password || '********';
            }

            if (tableMeta.columnType === 'clickHere') {
                return <RightCircleOutlined />;
            }

            if (tableMeta.columnType === 'date') {
                return moment.utc(value).toString();
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'active') {
                return value ? 'Yes' : 'No';
            }

            if (tableMeta.columnType === 'image') {
                return (
                    <div>
                        {value && (
                            <img
                                src={value}
                                style={{ borderRadius: '50%', height: '40px', width: '40px', overflow: 'hidden' }}
                            />
                        )}
                    </div>
                );
            }

            if (tableMeta.columnType === 'dropDown') {
                const options = getOptions(record.role);

                return (
                    <div style={{ paddingRight: '0', width: '100px' }}>
                        {isDropdownVisible[record.id] ? (
                            <select
                                onChange={(e) => dropDownOnChange(e, record.id)}
                                name={tableMeta.name}
                                style={{
                                    border: '1px solid grey',
                                    outline: 'none',
                                    borderRadius: '5px',
                                    padding: '2px',
                                    cursor: 'pointer',
                                }}>
                                {options.map((o: any) => (
                                    <option key={o} className='custom-option'>
                                        {o}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <div onClick={() => toggleDropdown(record.id)} style={{ cursor: 'pointer' }}>
                                <img src={AssignAgentIcon} alt='AssignAgentIcon' />
                            </div>
                        )}
                    </div>
                );
            }

            return value ?? '--';
        },
    }));

    const profileHeader = () => <DefaultProfileHeader />;

    const getHeader = () => (
        <DefaultTableHeader
            headerName={props.headerName}
            totalCount={totalCount}
            countText={props.headerName === 'Experts' ? 'Profiles' : 'Items'}
            onAdd={() => props.onAdd && props.onAdd()}
            setSearchQuery={setSearchQuery}
            setPhoneSearchQuery={setPhoneSearchQuery}
            setCitySearchQuery={setCitySearchQuery}
            setStateSearchQuery={setStateSearchQuery}
            addName={props.addName ? props.addName : ''}
            disableAdd={props.disableAdd}
            disableSearch={disableSearch}
            isCreateMode={isCreateMode}
        />
    );

    return (
        <div className='warraper'>
            {profileHeader()}
            <div className='landing-container'>
                {openUpdatePasswordModal && <ResetPasswordModal onCancelClose={onCancelClose} userId={userId} />}
                {getHeader()}
                <div className='dropdown-group'>{getFilter()}</div>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={apiListingData}
                    onChange={onPageChange}
                    pagination={{ ...pagination, total: totalCount, showSizeChanger: false }}
                    scroll={{ x: 'max-content' }}
                    style={{ minHeight: '100vh' }}
                    bordered
                />
            </div>
        </div>
    );
};

AbstractLanding.defaultProps = {
    refreshData: false,
};

export default AbstractLanding;
