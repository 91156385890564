/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker, Switch, Table } from 'antd';
import moment from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DownOutlined, EditFilled, RightCircleOutlined } from '@ant-design/icons';

import { InputInterface, OnAddArguments } from '../../../utils/forms/FormInterface';
import useEffectExcludingMount from '../../../hooks/useEffectExcludingMount';
import allowTableShort from '../../../utils/TableShort';
import DefaultTableHeader from './DefaultTableHeader';
import callApi from '../../../Api/config';
import Dropdown from '../../../components/Dropdown';

import '../entity.scss';
import { AppRoutes } from '../../../constants/AppConstant';
import { APPLICATION, PLAN_SPONSOR, USERS } from '../../../constants/EntityName';
import ResetPasswordModal from '../../../components/ResetPasswordModal';
import DefaultProfileHeader from './DefaultProfileHeader';
import AssignAgentIcon from '../../../assets/svg/assign_agent.svg';

type FilterList = any;

interface AbstractLandingProps {
    headerName: string;
    entityName: string;
    tableHeaders: Array<InputInterface>;
    refreshData?: boolean;
    onAdd?: (argument1?: OnAddArguments) => void;
    onSlotClick?: any;
    addName?: string;
    entityId?: string;
    code?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    filtersList?: FilterList[];
    setFiltersList?: React.Dispatch<React.SetStateAction<FilterList[]>>;
    handleEdit?: (userData: any) => void;
    orderStatusQueryParams?: any;
    handleModal?: () => void;
    onSelect?: (arg: any) => any;
    paymentToggleStatus?: (status: any) => any | undefined;
    serviceToggleStatus?: (status: any) => any | undefined;
    recordCount?: (count: any) => any | undefined;
}

let previousPaginationCount = 0;

const AbstractLanding = (props: AbstractLandingProps) => {
    const {
        entityName,
        onSlotClick,
        disableSearch,
        filtersList,
        paymentToggleStatus,
        serviceToggleStatus,
        recordCount,
    } = props;
    const [apiListingData, setApiListingData] = useState<any>([] as any);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [totalCount, setTotalCount] = useState<number>();
    const [searchQuery, setSearchQuery] = useState<string>();
    const [searchPhoneQuery, setPhoneSearchQuery] = useState<string>();
    const [searchCityQuery, setCitySearchQuery] = useState<string>();
    const [searchStateQuery, setStateSearchQuery] = useState<string>();
    const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);
    const [userId, setUserId] = useState('');
    const [isDropdownVisible, setDropdownVisible] = useState<any>({});

    const toggleDropdown = (value: string) => {
        setDropdownVisible({ ...isDropdownVisible, [value]: value });
    };

    const [selectedFilter, setSelectedFilter] = useState<Array<{ key: string; value: CheckboxValueType[] }>>([]);

    const location = useLocation();

    // const PlanSponsorOptions = apiListingData
    //     .filter((item: any) => item.role !== 'Plan Sponsor')
    //     .map((item: any) => item.full_name);
    // const AdvisorOptions = apiListingData
    //     .filter((item: any) => item.role !== 'Advisor' || item.role !== 'Plan Sponsor')
    //     .map((item: any) => item.full_name);
    // const AgentOptions = apiListingData
    //     .filter((item: any) => item.role !== 'Plan Sponsor' || item.role !== 'Agent')
    //     .map((item: any) => item.full_name);

    const PlanSponsorOptions = useMemo(
        () => apiListingData.filter((item: any) => item.role !== 'Plan Sponsor').map((item: any) => item.full_name),
        [apiListingData],
    );

    const AdvisorOptions = useMemo(
        () =>
            apiListingData
                .filter((item: any) => item.role !== 'Advisor' || item.role !== 'Plan Sponsor')
                .map((item: any) => item.full_name),
        [apiListingData],
    );

    const AgentOptions = useMemo(
        () =>
            apiListingData
                .filter((item: any) => item.role !== 'Plan Sponsor' || item.role !== 'Agent')
                .map((item: any) => item.full_name),
        [apiListingData],
    );

    const getOptions = (roleName: string) => {
        switch (roleName) {
            case 'Agent':
                return ['Select an option', 'None', ...PlanSponsorOptions];
            case 'Advisor':
                return ['Select an option', 'None', ...AdvisorOptions];
            case 'Plan Sponsor':
                return ['Select an option', 'None', ...AgentOptions];
            default:
                return ['None'];
        }
    };

    const dropDownOnChange = (e: any, recordId: string) => {
        const value = e.target.value;
        if (value === 'None') {
            setDropdownVisible((prevState: any) => {
                const newState = { ...prevState };
                delete newState[recordId];
                return newState;
            });
        }
        props.onSelect && props.onSelect(e);
    };

    const handleEditClick = (id: any) => {
        setUserId(id);
        setOpenUpdatePasswordModal(true);
    };

    const onCancelClose = () => {
        setOpenUpdatePasswordModal(false);
    };

    const getData = async (paginationEvent = pagination) => {
        const queryParams: any = {};
        let res: any;

        if (props.entityId && props.code) {
            queryParams[props.code] = props.entityId;
        }

        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }

        if (searchQuery) {
            if (entityName === AppRoutes.OrderList) {
                queryParams.orderId = searchQuery;
            } else {
                queryParams.search = searchQuery;
            }
        }

        if (selectedFilter.length) {
            selectedFilter.forEach((v) => {
                queryParams[v.key] = v.value.join(',');
            });
        }

        setLoading(true);

        if (!queryParams.page || !queryParams.limit) {
            res = await callApi(props.entityName, 'GET');
        } else {
            res = await callApi(props.entityName, 'GET', null, queryParams);
        }

        if (res?.status && res?.data?.data) {
            const apiData = res?.data?.data;

            const getTotalCount = () => {
                if (apiData?.totalCount > 10) {
                    const totalCount = apiData?.results?.length >= 10 ? (+apiData.page + 1) * 10 : +apiData.page * 10;

                    return totalCount;
                }

                return res?.data?.data.totalItems;
            };

            const totalCount = getTotalCount();
            setPaginationCount(totalCount);

            recordCount && recordCount(res?.data?.data?.totalCount);

            if (entityName === USERS) {
                res.data.data.results = res?.data?.data?.results.map((i: any) => ({
                    ...i,
                    roleName: i.roleId.roleName,
                }));
            }

            if (entityName === APPLICATION) {
                res.data.data.results = res?.data?.data;
            }

            if (entityName === PLAN_SPONSOR) {
                res.data.data.results = res?.data?.data.map((item: any) => {
                    const createdAt = new Date(item.createdAt);
                    const updatedAt = new Date(item.updatedAt);

                    const formattedCreatedAt = `${createdAt.getDate().toString().padStart(2, '0')}/${(createdAt.getMonth() + 1).toString().padStart(2, '0')}/${createdAt.getFullYear()}`;
                    const formattedUpdatedAt = `${updatedAt.getDate().toString().padStart(2, '0')}/${(updatedAt.getMonth() + 1).toString().padStart(2, '0')}/${updatedAt.getFullYear()}`;

                    return {
                        ...item,
                        createdAt: formattedCreatedAt,
                        updatedAt: formattedUpdatedAt,
                    };
                });
            }

            if (entityName === APPLICATION) {
                res.data.data.results = res?.data?.data?.results.map((i: any) => ({
                    ...i,
                    status:
                        i.status === 'SUBMITTED' ? (
                            <div
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: '#FFD600',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '28px',
                                    color: '#055133',
                                }}>
                                SUBMITTED
                            </div>
                        ) : i.status === 'Agreement signed' ? (
                            <div
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: '#13A3E0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '28px',
                                    color: '#055133',
                                }}>
                                Active
                            </div>
                        ) : (
                            i.status === 'Invoice paid' && (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#94FFD4',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '28px',
                                        color: '#055133',
                                    }}>
                                    SUBMITTED
                                </div>
                            )
                        ),
                }));
            }

            setApiListingData(res.data.data.results);
            setTotalCount(totalCount);
        }

        setLoading(false);
    };

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const onPageChange = async (event: any) => {
        setPagination(event);
        getData(event);
    };

    const onSummitFilter = (filterName: string, list: CheckboxValueType[]) => {
        const newFilter = selectedFilter.filter((value) => value.key !== filterName);

        if (list.length) {
            newFilter.push({
                key: filterName,
                value: list,
            });
        }
        setSelectedFilter(newFilter);
    };

    useEffectExcludingMount(() => {
        if (searchQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchPhoneQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchCityQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchStateQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
    }, [searchQuery, searchPhoneQuery, searchCityQuery, searchStateQuery]);

    useEffectExcludingMount(() => {
        getData();
    }, [props.refreshData]);

    // THis is default api call for first time also
    useEffect(() => {
        getData({ ...pagination, current: 1 });
    }, [selectedFilter, entityName]);

    useEffect(() => {
        if (props.orderStatusQueryParams) {
            onSummitFilter('orderStatus', [props.orderStatusQueryParams]);
        }
    }, [props.orderStatusQueryParams]);

    const onAddClick = (record: any) => {
        if (props.onAdd) {
            props.onAdd(record);
        }
    };

    const getFilter = () => (
        <div className='flex'>
            {filtersList?.map((filter) => {
                switch (filter.filterType) {
                    case 'DatePicker':
                        return (
                            <DatePicker
                                key={filter.filterKey}
                                allowClear
                                suffixIcon={<DownOutlined />}
                                className='date-filter'
                                format='YYYY-MM-DD'
                                name={filter.filterName}
                                placeholder={filter.placeholder}
                                onChange={(_, dateString) => onSummitFilter(filter.key, [dateString])}
                            />
                        );
                    default:
                        return (
                            <Dropdown
                                checkBoxOptions={filter.options}
                                onSubmitFilter={onSummitFilter}
                                filterName={filter.filterName}
                                filterKey={filter.filterKey}
                                key={filter.filterKey}
                            />
                        );
                }
            })}
        </div>
    );

    const columns: any = props.tableHeaders.map((tableMeta: any) => ({
        key: tableMeta.tableKey,
        title: tableMeta.tableLabel,
        dataIndex: tableMeta.tableKey,
        // eslint-disable-next-line no-nested-ternary
        colSpan: tableMeta.type === 'image' ? 0 : tableMeta.type === 'combinedColumn' ? 2 : null,
        onCell: (record: any) => ({
            style: {
                cursor:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'pointer'
                        : 'default',
                textDecoration:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'underline'
                        : '',
            },
            onClick: () => {
                if (tableMeta.allowAddRecordClick) {
                    // This code may be used in future
                    // if (routesEntityArray.includes(entityName)) {
                    //     history.push(`${entityName}/${record?.id}`);
                    // } else {
                    onAddClick(record);
                    // }
                }

                // if (tableMeta.allowAddRedirectOnClicks) {
                //     history.push(`${ORDER_LIST_ENTITY}/${record.order.id}`);
                // }

                if (tableMeta.allowSlotRecordClick) {
                    const tableMetaUrl = tableMeta.url;

                    if (tableMetaUrl) {
                        history.push(`${entityName}/${record?.id}/${tableMetaUrl}`);
                    } else {
                        onSlotClick?.(tableMeta.tableKey, { id: record.id, name: record.name });
                    }
                }
            },
        }),

        sorter: allowTableShort(tableMeta.enableSort, tableMeta.tableKey),

        render: (value: any, record: any) => {
            if (tableMeta.columnType === 'userName') {
                return `${record.firstName} ${record.lastName}`;
            }

            if (tableMeta.columnType === 'orderIdHash') {
                return `${record.order.orderIdHash}`;
            }
            if (tableMeta.columnType === 'orderId') {
                return `${record.order.id}`;
            }

            if (tableMeta.columnType === 'countriesName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.countries?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'displayName') {
                // when Items are deleted then the corresponding inventories are not deleted. In that case the value will be undefined and hence the optional chaining
                return value?.name || '--';
            }

            if (tableMeta.columnType === 'servicesObjectArray') {
                return value
                    ?.map(
                        (services: { level: string; serviceDetail: string; serviceDetailLevel: string }) =>
                            `${services?.level} - ${services?.serviceDetail} - ${services?.serviceDetailLevel}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'facebookLink') {
                return `${record.socialMediaLinks.facebook} `;
            }
            if (tableMeta.columnType === 'twitterLink') {
                return `${record.socialMediaLinks.twitter}`;
            }

            if (tableMeta.columnType === 'contactNumbersMultiple') {
                return `${record.contactNumbers.join(' | ')}`;
            }

            if (tableMeta.columnType === 'documentsDisplayNameObject') {
                return (
                    record?.documents
                        ?.map((documentDisplayNameObject: { name: string }) => documentDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'featureDisplayNameObject') {
                return (
                    record?.features
                        ?.map(
                            (featuresDisplayNameObject: { description: string }) =>
                                featuresDisplayNameObject?.description,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceAttributesDisplayNameObject') {
                return (
                    record?.serviceAttributes
                        ?.map(
                            (serviceAttributeDisplayNameObject: { name: string }) =>
                                serviceAttributeDisplayNameObject?.name,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'plansDisplayNameObject') {
                return (
                    record?.plans
                        ?.map((planDisplayNameObject: { title: string }) => planDisplayNameObject?.title)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'parametersDisplayNameObject') {
                return (
                    record?.parameters
                        ?.map((parametersDisplayNameObject: { name: string }) => parametersDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'categoryName') {
                return `${record?.category?.title || '--'}`;
            }

            if (tableMeta.columnType === 'titleName') {
                return `${record?.make?.title || '--'}`;
            }

            if (tableMeta.columnType === 'FuelTypeName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.fuelType?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'discountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.discountType || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'referralDiscountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.couponTarget || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'commissionType') {
                // In that case the value will be undefined and hence the optional chaining
                const commissionTypeValue = record?.commissionType || '--';
                return commissionTypeValue.charAt(0).toUpperCase() + commissionTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'displayNameTitle') {
                return `${record?.title?.heading || '--'}`;
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'displayNameObjectArray') {
                return value
                    ?.map(
                        (displayNameObject: { name: string; mobile: string }) =>
                            `${displayNameObject?.name} - ${displayNameObject?.mobile}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'password') {
                return value?.password || '********';
            }

            if (tableMeta.columnType === 'clickHere') {
                return <RightCircleOutlined />;
            }

            if (tableMeta.columnType === 'date') {
                return moment.utc(value).toString();
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'active') {
                return value ? 'Yes' : 'No';
            }

            if (tableMeta.columnType === 'image') {
                return (
                    <div>
                        {value && (
                            <img
                                src={value}
                                style={{ borderRadius: '50%', height: '40px', width: '40px', overflow: 'hidden' }}
                            />
                        )}
                    </div>
                );
            }

            if (tableMeta.columnType === 'dropDown') {
                const options = getOptions(record.role);

                return (
                    <div style={{ paddingRight: '0', width: '100px' }}>
                        {isDropdownVisible[record.id] ? (
                            <select
                                onChange={(e) => dropDownOnChange(e, record.id)}
                                name={tableMeta.name}
                                style={{
                                    border: '1px solid grey',
                                    outline: 'none',
                                    borderRadius: '5px',
                                    padding: '2px',
                                    cursor: 'pointer',
                                }}>
                                {options.map((o: any) => (
                                    <option key={o} className='custom-option'>
                                        {o}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <div onClick={() => toggleDropdown(record.id)} style={{ cursor: 'pointer' }}>
                                <img src={AssignAgentIcon} alt='AssignAgentIcon' />
                            </div>
                        )}
                    </div>
                );
            }

            return value ?? '--';
        },
    }));

    const profileHeader = () => <DefaultProfileHeader />;

    const getHeader = () => (
        <DefaultTableHeader
            headerName={props.headerName}
            totalCount={totalCount}
            countText={props.headerName === 'Experts' ? 'Profiles' : 'Items'}
            onAdd={() => props.onAdd && props.onAdd()}
            setSearchQuery={setSearchQuery}
            setPhoneSearchQuery={setPhoneSearchQuery}
            setCitySearchQuery={setCitySearchQuery}
            setStateSearchQuery={setStateSearchQuery}
            addName={props.addName ? props.addName : ''}
            disableAdd={props.disableAdd}
            disableSearch={disableSearch}
        />
    );

    return (
        <>
            {profileHeader()}
            <div className='landing-container'>
                {openUpdatePasswordModal && <ResetPasswordModal onCancelClose={onCancelClose} userId={userId} />}
                {getHeader()}
                {/* This is the dropdown for filter in listing UI */}
                <div className='dropdown-group'>{getFilter()}</div>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={apiListingData}
                    onChange={onPageChange}
                    pagination={{ ...pagination, total: totalCount }}
                    scroll={{ x: 'max-content' }}
                    style={{ minHeight: '100vh' }}
                    bordered
                />
            </div>
        </>
    );
};

AbstractLanding.defaultProps = {
    refreshData: false,
};

export default AbstractLanding;
