/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
// import TabPane from 'antd/lib/tabs/TabPane';
// import { ColumnsType } from 'antd/lib/table';

import { useParams } from 'react-router-dom';

import FooterComponent from '../../../components/FooterComponent';
import DefaultProfileHeader from '../../Entity/AbstractLanding/DefaultProfileHeader';
import DefaultTableHeader from '../../Entity/AbstractLanding/DefaultTableHeader';

const { Link } = Typography;
import SearchPlanSponsorModal from '../../../components/SearchPlanSponsorModal';
import callApi from '../../../Api/config';
import { APPLICATION } from '../../../constants/EntityName';
import { toastError } from '../../../service/Toast';
import DocIcon from '../../../assets/svg/docIcon.svg';
import CommentIcon from '../../../assets/svg/commentImg.svg';
import CommentModal from '../../../components/CommentModal';
import './style.scss';
import PdfViewer from '../../../components/PdfViewer';

// interface User {
//     key: string;
//     fullName: string;
//     email: string;
//     phone: string;
//     companyName: string;
//     title: string;
// }

const ApplicationDetailPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [applicationData, setApplicationData] = useState<any>();
    const [applicationDocData, setApplicationDocData] = useState<any>();
    // eslint-disable-next-line no-unused-vars
    const [commentData, setCommentData] = useState<string>('');
    const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
    const [isDocModalVisible, setIsDocModalVisible] = useState(false);
    const [docFilePath, setDocFilePath] = useState<{ path: string; name: string }>({ path: '', name: '' });

    const handleOpenModal = () => {
        setShowCommentModal(true);
    };

    const handleCloseModal = () => {
        setShowCommentModal(false);
    };

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => {
        setIsModalVisible(false);
    };

    const { id } = useParams<{ id: string }>();
    //     authorizedUsers: ['Peter Sponsor', 'VP Human Resource'],
    //     accountingTeam: 'cfo@acmeinc.com',
    //     applicant: {
    //         name: 'Ralph Edwards',
    //         title: 'Western regional head',
    //         company: 'Advisor Inc',
    //         contact: 'ralph@advisorinc.com +1.886.708.8647',
    //     },
    // };

    // const users: User[] = [
    //     {
    //         key: '1',
    //         fullName: 'Black, Marvin',
    //         email: 'deanna.curtis@example.com',
    //         phone: '(408) 555-0120',
    //         companyName: 'Binford Ltd.',
    //         title: 'Data Scientist',
    //     },
    //     {
    //         key: '2',
    //         fullName: 'Nguyen, Shane',
    //         email: 'alma.lawson@example.com',
    //         phone: '(907) 555-0101',
    //         companyName: 'Abstergo Ltd.',
    //         title: 'Sales Manager',
    //     },
    //     {
    //         key: '3',
    //         fullName: 'Flores, Juanita',
    //         email: 'debra.holt@example.com',
    //         phone: '(702) 555-0122',
    //         companyName: 'Barone LLC.',
    //         title: 'Teller',
    //     },
    // ];

    // const columns: ColumnsType<User> = [
    //     {
    //         title: 'Full Name',
    //         dataIndex: 'fullName',
    //         key: 'fullName',
    //     },
    //     {
    //         title: 'Email',
    //         dataIndex: 'email',
    //         key: 'email',
    //         render: (text) => <a href={`mailto:${text}`}>{text}</a>,
    //     },
    //     {
    //         title: 'Phone',
    //         dataIndex: 'phone',
    //         key: 'phone',
    //     },
    //     {
    //         title: 'Company Name',
    //         dataIndex: 'companyName',
    //         key: 'companyName',
    //     },
    //     {
    //         title: 'Title',
    //         dataIndex: 'title',
    //         key: 'title',
    //     },
    // ];

    const fetchApplicationData = async () => {
        try {
            const response = await callApi(`${APPLICATION}/${id}`, 'GET');
            if (response?.status) {
                setApplicationData(response?.data?.data?.results);
            } else {
                console.error('Failed to fetch application data');
            }
        } catch (error) {
            toastError('Error fetching application data');
        }
    };
    useEffect(() => {
        fetchApplicationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDocumentData = async () => {
        try {
            const response = await callApi(`documents/${id}`, 'GET');
            if (response?.status) {
                setApplicationDocData(response?.data?.data);
            } else {
                console.error('Failed to fetch application data');
            }
        } catch (error) {
            toastError('Error fetching application data');
        }
    };

    useEffect(() => {
        fetchDocumentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileClick = (filePath: string, fileName: string) => {
        setIsDocModalVisible(true);
        setDocFilePath({ path: filePath, name: fileName });
    };

    const today = new Date().toLocaleDateString('en-US');

    const fetchCommentData = async () => {
        try {
            const response = await callApi(`documents/${id}`, 'GET');
            if (response?.status) {
                setCommentData(response?.data?.data[0]?.comment);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCommentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = () => {
        setIsDocModalVisible(false);
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(docFilePath.path, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch the PDF file.');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', docFilePath.name + '.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };

    const modalTitle = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Service Agreement</span>
            <Button
                type='primary'
                onClick={handleDownload}
                style={{
                    backgroundColor: '#E87524',
                    borderColor: '#E87524',
                    marginRight: '40px',
                    color: 'black',
                    fontWeight: '500',
                    paddingTop: '5px',
                    borderRadius: '5px',
                }}>
                Download PDF
            </Button>
        </div>
    );

    const AuthUserNames = applicationData?.authorizedUsers?.map((item: any) => item.authorizedUserId.fullName);

    return (
        <div className='plan-sponsor-dashboard'>
            <DefaultProfileHeader />
            <DefaultTableHeader
                headerName={'Applications'}
                setSearchQuery={() => 'test'}
                setPhoneSearchQuery={() => 'test'}
                setCitySearchQuery={() => 'test'}
                setStateSearchQuery={() => 'test'}
                disableSearch={true}
                disableAdd={true}
                isShowUploadButton={true}
                applicationId={applicationData?.id}
                fetchDocumentData={fetchDocumentData}
                isCreateMode={true}
            />
            <div className='application-info-header'>
                <div className='application-info-header-content'>
                    <h2>Application submitted date:</h2>
                    <p>{applicationData?.submittedDate}</p>
                </div>
                <div className='application-info-header-content'>
                    <h2>Application Id:</h2>
                    <p>{applicationData?.id}</p>
                </div>
                <div className='application-info-header-content'>
                    <h2>Application status:</h2>
                    <p>{applicationData?.adminStatus}</p>
                </div>
                {/* <div className='application-info-header-content'>
                    <h2>Premium Split:</h2>
                    <p>Default</p>
                </div> */}
            </div>
            <div className='dashboard-row'>
                <div className='application-container-sponsor'>
                    <div className='app-container-plan-doc'>
                        <div className='info-card'>
                            <div className='info-card-heading'>
                                <h3>Plan sponsor information</h3>
                            </div>
                            <div className='plan-sponsor-body'>
                                <div className='plan-sponsor-body-container'>
                                    <h4 className='info-card-title'>{applicationData?.companyName}</h4>
                                    <p>{applicationData?.website}</p>
                                    <p>SIC: {applicationData?.sicCode}</p>
                                    <p>{applicationData?.address}</p>
                                    <p>
                                        {applicationData?.city}, {applicationData?.state}, {applicationData?.zipCode}
                                    </p>
                                </div>
                                <p className='layoff-info'>
                                    Planned layoff in next 120 days: {applicationData?.plannedLayoffs ? 'Yes' : 'No'}
                                </p>
                            </div>
                        </div>
                        <div className='documents-card'>
                            <div className='document-heading'>
                                <h3>Documents</h3>
                            </div>
                            <div className='documents-card-body'>
                                {applicationDocData?.map(
                                    (doc: any) =>
                                        doc.category !== 'Application-pdf' && (
                                            <Link
                                                key={doc.id}
                                                onClick={() => handleFileClick(doc?.filePath, doc.category)}>
                                                <img src={DocIcon} alt='Doc Icon' width={12} height={14} />
                                                <span style={{ marginLeft: '30px' }}>{doc.category}</span>
                                            </Link>
                                        ),
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='plan-user-table-container'>
                        <div className='user-plan-information-table'>
                            <div className='plan-user-heading'>
                                <h3>{`Plan information as of: ${today}`}</h3>
                                <img
                                    src={CommentIcon}
                                    alt='CommentIcon'
                                    height={32}
                                    width={132}
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleOpenModal}
                                />
                            </div>
                            {/* <Tabs defaultActiveKey='1'> */}
                            {/* <TabPane tab={`Plan information as of: ${today}`} key='2'> */}
                            <div className='plan-info'>
                                <div className='info-row'>
                                    <span className='label'>Legal name of the plan:</span>
                                    <span className='value'>{applicationData?.planName}</span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total eligible participants:</span>
                                    <span className='value'>
                                        {Number(applicationData?.totalEligibleParticipants)
                                            ? Number(applicationData.totalEligibleParticipants).toLocaleString()
                                            : '0'}
                                    </span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total active participants:</span>
                                    <span className='value'>
                                        {Number(applicationData?.totalActiveParticipants)
                                            ? Number(applicationData.totalActiveParticipants).toLocaleString()
                                            : '0'}
                                    </span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total current assets:</span>
                                    <span className='value'>
                                        {'$' +
                                            (Number(applicationData?.totalCurrentPlanAssets)
                                                ? Number(applicationData.totalCurrentPlanAssets).toLocaleString()
                                                : '0')}
                                    </span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total outstanding loan balance:</span>
                                    <span className='value'>
                                        {'$' +
                                            (Number(applicationData?.totalOutstandingLoanBalances)
                                                ? Number(applicationData.totalOutstandingLoanBalances).toLocaleString()
                                                : '0')}
                                    </span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>
                                        Total number of participants with an outstanding loan balance:
                                    </span>
                                    <span className='value'>
                                        {Number(applicationData?.totalParticipantsWithLoans)
                                            ? Number(applicationData.totalParticipantsWithLoans).toLocaleString()
                                            : '0'}
                                    </span>
                                </div>
                            </div>
                            {/* </TabPane> */}
                            {/* <TabPane tab='Users' key='1'>
                                    <Table<User> columns={columns} dataSource={users} pagination={false} size='small' />
                                </TabPane> */}
                            {/* </Tabs> */}
                        </div>
                        <CommentModal
                            commentData={applicationDocData && applicationDocData}
                            onClose={handleCloseModal}
                            visible={showCommentModal}
                        />
                    </div>
                </div>
                <div className='contacts-card'>
                    <div className='contact-heading'>
                        <h3>Company contacts</h3>
                    </div>
                    <div className='contact-section'>
                        <Button
                            onClick={showModal}
                            className='auth-user-btn'
                            type='primary'
                            icon={<PlusSquareOutlined />}>
                            Additional authorized user
                        </Button>
                        <h4 className='contact-card-auth-title'>Authorized user(s)</h4>
                        <div className='contact-card-body'>
                            {applicationData?.authorizedUsers.map((item: any) => (
                                <div key={item?.id} style={{ paddingTop: '15px' }}>
                                    <div style={{ paddingBottom: '15px' }}>
                                        <p>{item?.authorizedUserId?.fullName}</p>
                                        <p>{item?.authorizedUserId?.title}</p>
                                    </div>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <p style={{ marginBottom: '0px' }}>Contact:</p>
                                        <p>{item?.authorizedUserId?.email}</p>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='accounting-team'>
                        <h3>Accounting team</h3>
                        <div className='accounting-content'>
                            <p>Coming soon...</p>
                            <hr />
                            <h3>Applicant</h3>
                            <div className='accounting-subcontent'>
                                <p>{applicationData?.createdBy?.fullName}</p>
                                <p>{applicationData?.createdBy?.title}</p>
                                <p>{applicationData?.createdBy?.userCompany}</p>
                                <p>
                                    Contact: <br />
                                    {applicationData?.createdBy?.email}
                                </p>
                                <p>{applicationData?.createdBy?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-component'>
                <FooterComponent />
            </div>
            <SearchPlanSponsorModal
                isVisible={isModalVisible}
                onClose={hideModal}
                applicationId={applicationData?.id}
                refreshData={fetchApplicationData}
                planSponsorAuthName={AuthUserNames}
            />
            <Modal
                title={modalTitle}
                open={isDocModalVisible}
                onCancel={handleCancel}
                width={'80%'}
                footer={false}
                style={{ top: 20 }}>
                <PdfViewer pdfUrl={docFilePath.path} onClose={() => setIsDocModalVisible(false)} />
            </Modal>
        </div>
    );
};

export default ApplicationDetailPage;
