/* eslint-disable no-unused-vars */
import { Button, Image } from 'antd';
import { useEffect, useState } from 'react';

import LogoutIcon from '../../../assets/svg/ic_outline-logout.svg';
import ProfileIcon from '../../../assets/svg/profile_logo.svg';
import { deleteToken } from '../../../service/CookieManager';

const DefaultProfileHeader = () => (
    <div className='defaultProfileActionbar'>
        <div className='flex align-bottom'>
            <h2>Super Admin</h2>
        </div>
        <div className='flex align-center' style={{ marginRight: '14px' }}>
            <div
                className='flex align-center'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    deleteToken();
                    window.location.reload();
                }}>
                <img src={LogoutIcon} alt='logout' height={21} width={21} style={{ marginRight: '10px' }} />
                <p style={{ marginBottom: 0, fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 500 }}>Log out</p>
            </div>
            <img
                src={ProfileIcon}
                alt='logout'
                height={35}
                width={35}
                style={{ marginLeft: '20px', cursor: 'pointer' }}
            />
        </div>
    </div>
);

DefaultProfileHeader.defaultProps = {
    addName: '',
};

export default DefaultProfileHeader;
