import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

const userListFormData = [
    {
        title: 'User Details',
        name: 'fullName',
        label: 'Full Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Full Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'email',
        },
        rules: [{ message: customErrorMsg('Please enter email Id') }],
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'phoneNumber',
        label: 'Phone',
        fieldType: {
            name: 'text',
        },
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('Please enter phone number') }],
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'company',
        label: 'Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'roleName',
        label: 'Role',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Advisor', value: 'advisor' },
                { name: 'Agent', value: 'agent' },
                { name: 'Plan Sponsor', value: 'plan_sponsor' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Role',
            option: [
                { name: 'Advisor', value: 'advisor' },
                { name: 'Agent', value: 'agent' },
                { name: 'Plan Sponsor', value: 'plan_sponsor' },
            ],
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'plan_sponsor_company',
        label: 'Plan Sponsor Company',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Sponsor Company' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'permissions',
        label: '',
        fieldType: {
            name: 'permissions',
        },
        errorMsg: errorMsg,
        tableMeta: { title: 'permissions', columnType: 'permissions' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
];

export { userListFormData };
