import React from 'react';

import './style.scss';

const FooterComponent = () => (
    <>
        <footer className='footer'>
            <div>Copyright © 2024</div>
            <div>Custodia Financial</div>
        </footer>
    </>
);

export default FooterComponent;
