import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DefaultTableHeader from '../../Entity/AbstractLanding/DefaultTableHeader';
import DefaultProfileHeader from '../../Entity/AbstractLanding/DefaultProfileHeader';
import FooterComponent from '../../../components/FooterComponent';
import { APPLICATION } from '../../../constants/EntityName';
import './style.scss';

const BASE_URL = process.env.REACT_APP_API_URL;

const ApplicationDetail = (props: any) => {
    const history = useHistory();
    const { title, id } = props;

    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState<any>(null);

    const handleBackClick = () => {
        history.push('/applications');
    };

    useEffect(() => {
        const fetchApplicationApiData = async () => {
            try {
                const response = await fetch(`${BASE_URL}${APPLICATION}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch data: ${response.statusText}`);
                }

                const result = await response.json();
                setData(result);
            } catch (err: any) {
                // eslint-disable-next-line no-console
                console.error(err.message);
            }
        };

        fetchApplicationApiData();
    }, [id]);

    const details = [
        { label: 'Applicant Full Name', value: 'Adi Advisor' },
        { label: 'Applicant Employer', value: 'Advisor Inc' },
        { label: 'Your Title', value: 'Western Regional Head' },
        { label: 'Email', value: 'Vinod@404Harmony.com' },
        { label: 'Phone Number', value: '+1 8847088647' },
        { label: 'Your Role', value: 'Advisor Agent' },
        { label: 'Plan Sponsor/Company name', value: 'Acme Inc' },
        { label: 'Standard Industrial Code (SIC)', value: '20201' },

        { label: 'Company Website', value: 'www.acmenic.com' },
        { label: 'Address', value: '123 Main street' },
        { label: 'City', value: 'Pembroke Pines' },
        { label: 'State', value: 'California' },

        { label: 'Zip Code', value: '32204' },
        { label: 'Do yo have planned layoffs in next 120 days', value: 'No' },
        { label: 'Legal name of plan', value: 'Acme Inc Employee Plan' },
        { label: 'Total eligible participants in the plan', value: '573' },

        { label: 'Total active participants in the plan', value: '430' },
        { label: 'Total current plan assets', value: '$543,123.00' },
        { label: 'Total outstanding loan balances', value: '$113,148.15' },
        { label: 'Total number of participants with a loan outstanding', value: '75' },

        { label: 'Details as of date', value: '05-Jul-2024' },
        { label: 'Authorized Signer Name', value: 'Peter Sponsor' },
        { label: 'Authorized Signer Title', value: 'VP, Human Resource' },
        { label: 'Authorized Signer Email', value: 'peter@acmenic.com' },

        { label: 'Authorized Signer Phone Number', value: '+001 510.579.9815' },
    ];

    const documents = [
        { id: 1, name: 'Invoice' },
        { id: 2, name: 'Service Agreement' },
        { id: 3, name: 'Policy' },
    ];

    return (
        <div className='application-details-container'>
            <DefaultProfileHeader />
            <DefaultTableHeader
                headerName={title}
                setSearchQuery={() => 'test'}
                setPhoneSearchQuery={() => 'test'}
                setCitySearchQuery={() => 'test'}
                setStateSearchQuery={() => 'test'}
            />

            <div className='application-body-container'>
                <button className='back-button' onClick={handleBackClick}>
                    &lt; back
                </button>

                <h2>{`Details for Application Id: ${id}`}</h2>
                <div className='details-section'>
                    <div className='details-grid'>
                        {details.map((detail, index) => (
                            <div key={index}>
                                <span className='label'>{detail.label}</span>
                                <span className='value'>{detail.value}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='documents-section'>
                    <h3>Documents</h3>
                    <div className='documents-list'>
                        {documents.map((doc) => (
                            <span key={doc.id} className='document-item'>
                                {`${doc.name}`}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
};

export default ApplicationDetail;
