// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal {
  width: 360px;
  background-color: #F8D6BD;
  border: 1px solid #E87524;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 60px;
  padding: 30px; }

.modal-header {
  position: absolute;
  top: 10px;
  right: 10px; }

.close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer; }

.commentBody {
  max-height: 243px;
  overflow-y: auto;
  background-color: #F8D6BD;
  padding: 5px;
  overflow-y: scroll; }

.commentBody::-webkit-scrollbar {
  display: block;
  width: 3px; }

.commentBody::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/CommentModal/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,aAAa,EAAA;;AAGjB;EACI,kBAAkB;EAClB,SAAS;EACT,WAAW,EAAA;;AAGf;EACI,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,eAAe,EAAA;;AAGnB;EACI,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB,EAAA;;AAGlB;EACI,cAAc;EACd,UAAU,EAAA;;AAGZ;EACI,sBAAsB;EACtB,kBAAkB,EAAA","sourcesContent":[".custom-modal {\n    width: 360px;\n    background-color: #F8D6BD;\n    border: 1px solid #E87524;\n    border-radius: 4px;\n    position: absolute;\n    right: 0;\n    top: 60px;\n    padding: 30px;\n}\n\n.modal-header {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n\n.close-button {\n    background: transparent;\n    border: none;\n    font-size: 24px;\n    cursor: pointer;\n}\n\n.commentBody {\n    max-height: 243px;\n    overflow-y: auto;\n    background-color: #F8D6BD;\n    padding: 5px;\n    overflow-y: scroll;\n}\n\n    .commentBody::-webkit-scrollbar {\n        display: block;\n        width: 3px;\n      }\n    \n      .commentBody::-webkit-scrollbar-thumb {\n          background-color: #000;\n          border-radius: 4px;\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
