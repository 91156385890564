import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import { VERIFY_ADMIN_EMAIL } from '../../constants/EntityName';
import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';

import './style.scss';

const VerifyEmail = () => {
    const [loading, setLoading] = useState(true);
    const [verificationText, setVerificationText] = useState('Verification under process...');
    const location = useLocation();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    useEffect(() => {
        if (!token) {
            toastError('Invalid or missing token.');
        }
    }, [token]);

    const signUpLinkHit = async () => {
        try {
            const res = await callApi(VERIFY_ADMIN_EMAIL, 'GET', null, { token });
            if (res?.data?.statusCode) {
                toastSuccess('Your email is verified successfully.');
                setVerificationText('Verified successfully. Redirecting...');
                setTimeout(() => {
                    history.push({
                        pathname: '/login',
                    });
                }, 2000);
            } else {
                toastError(res?.data?.message);
                setVerificationText('Verification failed.');
            }
        } catch (error) {
            toastError('An error occurred while verifying your email.');
            setVerificationText('Verification failed.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        signUpLinkHit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='sign-up-container'>
            {loading ? (
                <div className='loader'>
                    <Spin size='large' />
                    <p>{verificationText}</p>
                </div>
            ) : (
                <h1 className='verified-text'>{verificationText}</h1>
            )}
        </div>
    );
};

export default VerifyEmail;
