/* eslint-disable no-unused-vars */
import React from 'react';
import './style.scss';

interface CommentModalProps {
    commentData: any;
    visible: boolean;
    onClose: () => void;
}

const CommentModal: React.FC<CommentModalProps> = ({ commentData, onClose, visible }) => {
    if (!visible) {
        return null;
    }

    return (
        <div className='custom-modal-overlay'>
            <div className='custom-modal' onClick={(e) => e.stopPropagation()}>
                <div className='modal-header'>
                    <button className='close-button' onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className='commentBody'>
                    {commentData?.map((item: any, index: number) => (
                        <div key={index}>
                            <h3>{`#${index + 1} ${item?.category}`}</h3>
                            <p>{item?.comment}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CommentModal;
