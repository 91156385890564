// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; }

.modalContainer {
  width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; }
  .modalContainer .resetModalBtnGroup {
    display: flex;
    justify-content: center; }

.modalContainer p {
  margin-bottom: 20px; }

.modalContainer button {
  background: #586f80;
  border-radius: 4px;
  height: 28px;
  color: white;
  font-weight: 600;
  text-align: center;
  border: none !important;
  transition: all 0.5s linear;
  margin: 0px 10px;
  padding: 0 12px;
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/ResetPasswordModal/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,YAAY,EAAA;;AAGhB;EACI,YAAY;EACZ,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa,EAAA;EAVjB;IAaQ,aAAa;IACb,uBAAuB,EAAA;;AAI/B;EACI,mBAAmB,EAAA;;AAGvB;EACI,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,eAAe,EAAA","sourcesContent":[".modalOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 999;\n}\n\n.modalContainer {\n    width: 300px;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n    z-index: 1000;\n\n    .resetModalBtnGroup {\n        display: flex;\n        justify-content: center;\n    }\n}\n\n.modalContainer p {\n    margin-bottom: 20px;\n}\n\n.modalContainer button {\n    background: #586f80;\n    border-radius: 4px;\n    height: 28px;\n    color: white;\n    font-weight: 600;\n    text-align: center;\n    border: none !important;\n    transition: all 0.5s linear;\n    margin: 0px 10px;\n    padding: 0 12px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
