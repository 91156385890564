import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Sidebar from '../../components/SideBar';
import { AppRoutes, entityArray } from '../../constants/AppConstant';

import Users from '../Users';
import CustodiaLogo from '../../assets/png/CustodiaLogo.png';

import UpdatePassword from '../UpdatePassword';
import './styles.scss';
import Applications from '../Applications';
import ApplicationDetail from '../Applications/ApplicationDetail';
import PlanSponsor from '../PlanSponsors';

const Landing = () => {
    const [moduleList] = useState<any>(entityArray);

    return (
        <Router>
            <div className='landing-box' style={{ display: 'flex' }}>
                <div style={{ width: '237px' }}>
                    <Sidebar title='Custodia' entityArr={moduleList} logo={CustodiaLogo} />
                </div>
                <div className='landing-box-content' style={{ flexGrow: 1 }}>
                    <Switch>
                        <Route exact path='/' component={Users} />
                        <Route exact path={AppRoutes.Users} component={Users} />

                        <Route exact path={`${AppRoutes.Users}`}>
                            <Users />
                        </Route>

                        <Route exact path={`${AppRoutes.UpdatePassword}`}>
                            <UpdatePassword />
                        </Route>

                        <Route exact path={`${AppRoutes.Applications}`}>
                            <Applications />
                        </Route>

                        <Route exact path={'/applications-details'}>
                            <ApplicationDetail />
                        </Route>

                        <Route exact path={`${AppRoutes.PlanSponsor}`}>
                            <PlanSponsor />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default Landing;
