/* eslint-disable no-console */
import { USERS } from '../constants/EntityName';

export const positionSort = (firstEntity: { position: number }, secondEntity: { position: number }) =>
    firstEntity.position - secondEntity.position;

export const getIncomingPayload = (entityName: string, apiData: any) => {
    switch (entityName) {
        case USERS: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'password') {
                    return { ...acc, [key]: '*****' };
                }

                return { ...acc, [key]: value };
            }, {});
        }
    }

    const entityNamesArray = entityName?.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        default:
            return apiData;
    }
};
