import { Button, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';
import { UPDATE_PASSWORD, USERS } from '../../constants/EntityName';
import { REGEX_PASSWORD } from '../../utils/Regexp/Regex';

import './style.scss';

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

const UpdatePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState<boolean>(false);

    const history = useHistory();

    const handlePasswordUpdate = async () => {
        const payload = {
            currentPassword,
            newPassword,
        };
        if (currentPassword === newPassword) {
            toastError('New password cannot be same as old password.');
        } else if (confirmPassword === newPassword) {
            try {
                const response: any = await callApi(UPDATE_PASSWORD, 'PATCH', payload);
                if (response?.status) {
                    setPasswordError(false);
                    toastSuccess(response?.data?.message);
                    history.push({
                        pathname: USERS,
                    });
                } else {
                    toastError(response?.data?.message);
                }
            } catch (error) {
                toastError('Invalid Password');
            }
        } else {
            setPasswordError(true);
        }
    };

    return (
        <div className='updatePasswordContainer'>
            <Form name='basic' initialValues={{ remember: true }} autoComplete='off' layout='vertical'>
                <h1>Update Password</h1>
                <Form.Item<FieldType>
                    label='Enter Current Password'
                    rules={[
                        { required: true, message: 'Please enter your password!' },
                        { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                    ]}>
                    <Input.Password
                        placeholder='Enter current password'
                        onChange={(e: any) => setCurrentPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item<FieldType>
                    label='Enter New Password'
                    rules={[
                        { required: true, message: 'Please enter your new password!' },
                        { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                    ]}>
                    <Input.Password
                        placeholder='Enter new password'
                        onChange={(e: any) => setNewPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item<FieldType>
                    label='Re-enter New Password'
                    rules={[
                        { required: true, message: 'Please re-enter your new password!' },
                        { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                    ]}>
                    <Input.Password
                        placeholder='Re-Enter new password'
                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                    />
                </Form.Item>

                <p
                    style={{
                        color: '#ff4d4f',
                        visibility: passwordError ? 'visible' : 'hidden',
                    }}>
                    Please check both passwords.
                </p>

                <Form.Item>
                    <Button type='primary' htmlType='submit' onClick={handlePasswordUpdate}>
                        Update Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default UpdatePassword;
