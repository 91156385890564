/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Applications = '/applications',
    PlanSponsor = '/companies',
    Products = '/products',
    Users = '/user',
    OrderList = '/orderList',
    AuthResetPassword = '/auth/reset-password',
    AuthSignUp = '/auth/signup',
    ForgotPasswordEmailSend = '/admin/email/send',
    ResetForgotPassword = '/admin/reset-password',
    VerifyAdminEmail = '/admin/verify-email',
    UpdatePassword = '/admin/update-password',
}
const entityArray = [
    { key: 'item-1', entityName: 'Applications', entityUrl: AppRoutes.Applications },
    { key: 'item-2', entityName: 'Users Management', entityUrl: AppRoutes.Users },
    { key: 'item-3', entityName: 'Plan Sponsor', entityUrl: AppRoutes.PlanSponsor },
];

export { entityArray };
