/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Button, Select, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { EyeOutlined } from '@ant-design/icons';

import Search from '../../../components/Search/Search';
import SearchByCity from '../../../components/Search/SearchByCity';
import SearchByPhone from '../../../components/Search/SearchByPhone';
import SearchByState from '../../../components/Search/SearchByState';

import AddIcon from '../../../assets/svg/basil_add-outline.svg';
import FileUploadModal from '../../../components/FileUploadModal';
import FilterIcon from '../../../assets/svg/Vector3.svg';
import { uploadImageApiCall } from '../../../Api/config';
import ActionRequiredModal from '../../../components/ActionRequiredModal';
import FilterModal from '../../../components/FilterModal';
import UploadIcon from '../../../assets/svg/ofacUpload.svg';

interface IDefaultTableHeader {
    headerName?: string;
    totalCount?: number;
    countText?: string;
    setSearchQuery: (value: any) => void;
    setPhoneSearchQuery: (value: any) => void;
    setCitySearchQuery: (value: any) => void;
    setStateSearchQuery: (value: any) => void;
    onAdd?: () => void;
    addName?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    isShowUploadButton?: boolean;
    isCreateMode?: boolean;
    applicationId?: string;
    fetchDocumentData?: () => void;
    handleFliterSubmit?: (data: string) => void;
}

const DefaultTableHeader = (props: IDefaultTableHeader) => {
    const { disableSearch, isShowUploadButton, applicationId, fetchDocumentData, handleFliterSubmit } = props;
    const [garageSearch, setGarageSearch] = useState('');
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [showApproveButton, setShowApproveButton] = useState(false);

    const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const [statusModal, isStatusModal] = useState<boolean>(false);

    const history = useHistory();

    const handleApprove = (reason: string) => {
        setIsApproveModalVisible(false);
    };

    const handleDecline = () => {
        setIsApproveModalVisible(false);
    };

    const handleUpload = async (file: File, comment: string): Promise<void> => {
        const formData: any = new FormData();
        formData.append('file', file);
        formData.append('comment', comment);

        try {
            const response = await uploadImageApiCall(`documents/${applicationId}`, formData);
            if (response?.isSuccess) {
                fetchDocumentData && fetchDocumentData();
                setShowApproveButton(true);
            }
        } catch (error) {
            console.error('Error in API call:', error);
            if (error instanceof Error) {
                console.error('Error name:', error.name);
                console.error('Error message:', error.message);
                console.error('Error stack:', error.stack);
            }
        }
    };

    useEffect(() => {
        if (props.addName === 'Garage Commission') {
            setGarageSearch('Garage');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Application') {
            return `${value?.toLowerCase()} details`;
        } else if (value === 'User') {
            return `by ${value?.toLowerCase()} details`;
        } else if (value === 'Company') {
            return `plan sponsor details`;
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getPhoneSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'Phone No';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getCitySearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'City Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getStateSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'State Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const handleFilter = () => {
        isStatusModal(true);
    };

    let optionData;

    if (props?.headerName === 'Applications List') {
        optionData = [
            { name: 'DRAFT', value: 'DRAFT' },
            { name: 'Agreement Shared', value: 'Agreement Shared' },
            { name: 'Invoice shared', value: 'Invoice shared' },
            { name: 'Waiting for OFAC', value: 'Waiting for OFAC' },
            { name: 'OFAC completed', value: 'OFAC completed' },
            { name: 'Application approved', value: 'Application approved' },
            { name: 'Application denied', value: 'Application denied' },
        ];
    } else {
        optionData = [
            { name: 'Agent', value: 'Agent' },
            { name: 'Advisor', value: 'Advisor' },
            { name: 'Plan Sponsor', value: 'Plan Sponsor' },
        ];
    }

    const handleApplicationRouteClick = () => {
        history.push('/applications');
    };

    const handleNewAddBtn = () => {
        if (props.headerName === 'Users Management') {
            history.push('/create-user');
        } else if (props.headerName === 'Plan Sponsor Management') {
            history.push('/create-company');
        } else if (props.headerName === 'Applications ') {
            props.onAdd && props.onAdd();
        }
    };

    return (
        <div className='actionbar'>
            <div className='flex align-center'>
                {props.headerName === 'Applications' ? (
                    <div style={{ paddingLeft: '15px', display: 'flex' }}>
                        <p
                            onClick={handleApplicationRouteClick}
                            style={{
                                fontSize: '24px',
                                marginBottom: 0,
                                cursor: 'pointer',
                            }}>{`${props.headerName} > `}</p>
                        <h2 style={{ marginLeft: '4px', fontWeight: 'bold' }}>Details</h2>
                    </div>
                ) : (
                    <h2>{props.headerName}</h2>
                )}
            </div>
            <div className='flex align-center'>
                {!disableSearch && (
                    <Search
                        setSearchQuery={props.setSearchQuery}
                        placeholderValue={getSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByPhone
                        setPhoneSearchQuery={props.setPhoneSearchQuery}
                        placeholderValue={getPhoneSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                {props.headerName === 'Order List' && (
                    <SearchByCity
                        setCitySearchQuery={props.setCitySearchQuery}
                        placeholderValue={getCitySearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByState
                        setStateSearchQuery={props.setStateSearchQuery}
                        placeholderValue={getStateSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                {/* {props.headerName !== 'Plan Sponsor List' && !props.isCreateMode && (
                    <div onClick={handleFilter}>
                        <img
                            src={FilterIcon}
                            alt='vector filter'
                            height={11}
                            width={22}
                            style={{ marginLeft: '30px', cursor: 'pointer' }}
                        />
                    </div>
                )} */}
                <div className='addBtn'>
                    {!props.disableAdd ? (
                        <Button className='newBtn' onClick={handleNewAddBtn}>
                            <img src={AddIcon} alt='addBtn' style={{ marginRight: '14px' }} />{' '}
                            {props.headerName === 'Applications' ? `New ${props.headerName}` : `New ${props.addName}`}
                        </Button>
                    ) : isShowUploadButton && !showApproveButton ? (
                        <Tooltip title='Coming soon'>
                            <Button disabled className='upload-btn' onClick={() => setIsUploadModalVisible(true)}>
                                <p>
                                    <img src={UploadIcon} alt='uploadIcon' width={24} height={24} />
                                    Upload OFAC Check
                                </p>
                            </Button>
                        </Tooltip>
                    ) : (
                        props.headerName === 'Applications' && (
                            <Button className='review-btn' onClick={() => setIsApproveModalVisible(true)}>
                                <EyeOutlined />
                                <p>Review Application</p>
                            </Button>
                        )
                    )}
                </div>

                {isUploadModalVisible && (
                    <FileUploadModal
                        isVisible={isUploadModalVisible}
                        onClose={() => setIsUploadModalVisible(false)}
                        onUpload={handleUpload}
                        applicationId={applicationId}
                    />
                )}
                {isApproveModalVisible && (
                    <ActionRequiredModal
                        visible={isApproveModalVisible}
                        onApprove={handleApprove}
                        onDecline={handleDecline}
                        onCancel={() => setIsApproveModalVisible(false)}
                    />
                )}
                {statusModal && (
                    <FilterModal
                        visible={true}
                        onSubmit={handleFliterSubmit}
                        onCancel={() => isStatusModal(false)}
                        optionData={optionData}
                    />
                )}
                {(props.headerName === 'Option Type' || props.headerName === 'Collection Type') && (
                    <div className='addBtn'>
                        <Button
                            onClick={() => {
                                setModalVisible(true);
                            }}>
                            Change {props.headerName} position
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

DefaultTableHeader.defaultProps = {
    addName: '',
};

export default DefaultTableHeader;
