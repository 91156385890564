import { Form, Input } from 'antd';

import { useState } from 'react';

import { REGEX_PASSWORD } from '../../utils/Regexp/Regex';
import callApi from '../../Api/config';
import { UPDATE_USER_PASSWORD } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';
import './style.scss';

type ModalComponentProps = {
    userId: string;
    onCancelClose: () => void;
};

const ResetPasswordModal: React.FC<ModalComponentProps> = ({ onCancelClose, userId }) => {
    const [newPassword, setNewPassword] = useState('');

    const handleCancel = () => {
        onCancelClose();
    };

    const handleResetClick = async () => {
        try {
            const res = await callApi(UPDATE_USER_PASSWORD, 'PATCH', { userId, newPassword });
            if (res?.data?.statusCode === 200) {
                toastSuccess('Password updated successfully.');
                setNewPassword('');
                onCancelClose();
            } else {
                toastError(res?.data?.message);
            }
        } catch (error) {
            toastError('An error occurred while resetting your password.');
        }
    };

    return (
        <>
            <div className='modalOverlay'></div>
            <div className='modalContainer'>
                <Form name='basic' initialValues={{ remember: true }} autoComplete='off' layout='vertical'>
                    <h1>Change Password</h1>
                    <Form.Item
                        rules={[
                            { required: true, message: 'Please enter your new password!' },
                            { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                        ]}>
                        <Input.Password
                            placeholder='Enter New Password'
                            onChange={(e: any) => setNewPassword(e.target.value)}
                        />
                    </Form.Item>
                </Form>
                <div className='resetModalBtnGroup'>
                    <button onClick={handleCancel}>Cancel</button>
                    <button onClick={handleResetClick}>Submit</button>
                </div>
            </div>
        </>
    );
};

export default ResetPasswordModal;
