import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import './style.scss';

interface ActionRequiredModalProps {
    visible: boolean;
    // eslint-disable-next-line no-unused-vars
    onApprove: (reason: string) => void;
    onDecline: () => void;
    onCancel: () => void;
}

const ActionRequiredModal: React.FC<ActionRequiredModalProps> = ({ visible, onApprove, onDecline, onCancel }) => {
    const [reason, setReason] = useState<string>('');

    const handleApprove = () => {
        onApprove(reason);
    };

    return (
        <Modal
            visible={visible}
            title='Action Required'
            onCancel={onCancel}
            className='action-modal'
            footer={[
                <Button key='decline' onClick={onDecline} className='decline-btn'>
                    Decline
                </Button>,
                <Button key='approve' type='primary' onClick={handleApprove} className='approve-btn'>
                    Approve
                </Button>,
            ]}>
            <p>Please provide a reason for accepting or declining this policy.</p>
            <Input.TextArea rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
        </Modal>
    );
};

export default ActionRequiredModal;
